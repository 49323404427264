import { useEffect, useContext } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    useAddCartProductMutation,
    useClearCartMutation,
    useGetCartQuery,
} from "../../../../features/shop/shopApiSlice";
import { saveCartId } from "../../model/cartSlice";
import { apiSlice } from "../../../../shared/api/apiSlice";
import { useAddRedeemCartProductMutation } from "../../../../features/redeem/redeemApiSlice";
import BigNumber from "bignumber.js";
import { NotificationContext } from "../../../../features/UI/Notification/NotificationContextProvider";

const useCart = () => {
    const { push } = useContext(NotificationContext);

    const dispatch = useAppDispatch();

    const cartId = useAppSelector((state) => state.cart.id);
    const { data: shopCart, refetch: shopCartRefetchQuery } = useGetCartQuery(cartId);
    const [addCartProduct, { isLoading: isAddCartProductLoading }] = useAddCartProductMutation();
    const [addCartRedeemProduct, { isLoading: isAddCartRedeemProductLoading }] = useAddRedeemCartProductMutation();
    const [clearCart, { isLoading: isClearCartLoading }] = useClearCartMutation();

    useEffect(() => {
        if (shopCart === undefined) {
            return;
        }

        if (shopCart === null) {
            if (cartId !== null) {
                dispatch(saveCartId(null));
            }
            return;
        }

        if (cartId !== shopCart.id) {
            dispatch(saveCartId(shopCart.id));
        }
    }, [shopCart]);

    const initShopCart = () => shopCartRefetchQuery();
    const resetShopCart = () => {
        dispatch(apiSlice.util.invalidateTags(["CartProducts"]));
        dispatch(saveCartId(null));
    };

    const onAddCartProduct = (
        productVariantId: string,
        quantity: number,
        brinxBuxForUnit: BigNumber,
        shareResourceId?: string,
        onAdded?: () => void,
    ) => {
        if (quantity <= 0) {
            console.warn("quantity <= 0");
            return;
        }

        if (brinxBuxForUnit.isLessThan(0)) {
            console.warn("brinxBuxForUnit < 0");
            return;
        }

        addCartProduct({
            cartId: cartId,
            productVariantId: productVariantId,
            quantity: quantity,
            shareResourceId: shareResourceId,
        })
            .unwrap()
            .then((r) => {
                if (r !== cartId) {
                    dispatch(saveCartId(r));
                }
                onAdded?.();
            })
            .catch((e) => {
                console.error(e);
                push("Not enough BB!");
            });
    };

    const onAddCartRedeemProduct = (
        productVariantId: string,
        quantity: number,
        collectionId: string,
        shareResourceId?: string,
    ) => {
        if (quantity <= 0) {
            console.warn("quantity <= 0");
            return;
        }

        addCartRedeemProduct({
            productVariantId,
            quantity,
            collectionId,
            shareResourceId,
        })
            .unwrap()
            .then((r) => {
                if (r !== cartId) {
                    dispatch(saveCartId(r));
                }
            })
            .catch((e) => console.error(e));
    };

    const onClearCart = async () => {
        if (cartId === null) {
            console.error("cartId is null");
            return false;
        }

        const result = await clearCart(cartId)
            .unwrap()
            .catch((e) => {
                console.error(e);
                return false;
            });

        return result;
    };

    return {
        shopCart,
        initShopCart,
        resetShopCart,
        onAddCartProduct,
        isAddCartProductLoading,
        onAddCartRedeemProduct,
        isAddCartRedeemProductLoading,
        onClearCart,
        isClearCartLoading,
    };
};

export default useCart;
