import BigNumber from "bignumber.js";
import { apiSlice } from "../../shared/api/apiSlice";
import { getQueryUrl } from "../../shared/lib/helpers/api";

export interface GetProductsRequest {
    tags?: string[];
    isAvailableForSale?: boolean;
    shopWidgetId?: string;
    top?: number;
}

export interface ProductDto {
    id: string;
    title: string;
    description?: string;
    tags?: string[];
    order: number;

    featuredImage: {
        url: string;
        width?: number;
        height?: number;
    };
    featuredPrice: {
        priceUsdCents: number;
        compareAtPriceUsdCents?: number;
    };

    inventoryQuantity?: number;
}

export interface GetProductsResponseItem extends ProductDto {}

export interface GetProductRequest {
    id: string;
}

export interface ProductFullDto {
    id: string;
    externalId: string | null;
    title: string;
    description: string;
    tags: string[];
    options: ProductOptionDto[];
    variants: ProductVariantDto[];
}

export interface SelectedOption {
    name: string;
    value: string;
}

export interface ProductOptionDto {
    id: string;
    name: string;
    values: string[];
}

export interface ProductVariantDto {
    id: string;
    productId: string;
    externalId: string;
    variantImage: {
        url: string;
        width?: number;
        height?: number;
    };
    title: string;
    priceUsdCents: number;
    compareAtPriceUsdCents?: number;
    selectedOptions?: SelectedOption[];
    inventoryQuantity?: number;
    isAvailableForSale: boolean;
}

export interface GetProductResponse extends ProductFullDto {}

export interface Cart {
    id: string;
    subtotalPriceUsdCents: number;
    totalQuantity: number;
    cartLineItems: CartLineItem[];
}

export interface CartLineItem {
    id: string;
    quantity: number;
    productVariant: {
        id: string;
        product: {
            id: string;
            title: string;
        };
        priceUsdCents: number;
        selectedOptions: {
            name: string;
            value: string;
        }[];
        image: {
            url: string;
            width: number | null;
            height: number | null;
        };
        inventoryQuantity: number | null;
    } | null;
}

interface GetCartResponse extends Cart {}

interface AddCartProductRequest {
    cartId: string | null;
    productVariantId: string;
    quantity: number;
    shareResourceId: string | undefined;
}

interface UpdateCartProductQuantityRequest {
    cartLineItemId: string;
    quantity: number;
}

export interface BuyItNowRequest {
    productVariantId: string;
    quantity: number;
    shareResourceId: string | undefined;
}

export interface CheckoutInfo {
    paymentIntentId: string | null;
    clientSecret: string | null;
    initialAmountUsdCents: number | null;
    shippingCostUsdCents: number;
}

export const shopApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProducts: builder.query<GetProductsResponseItem[], GetProductsRequest>({
            query: (request) => {
                const finalRequest = {
                    ...request,
                    tags: request.tags?.join(","),
                };
                return getQueryUrl(["/shop/products"], finalRequest);
            },
            providesTags: ["Products"],
        }),
        getProduct: builder.query<GetProductResponse, GetProductRequest>({
            query: (args) => {
                return getQueryUrl(["/shop/product/", args.id]);
            },
            providesTags: ["Products"],
        }),

        getCart: builder.query<GetCartResponse | null, string | null>({
            query: (id) => {
                return getQueryUrl(["shop", "cart", id]);
            },
            providesTags: ["CartProducts"],
        }),
        addCartProduct: builder.mutation<string, AddCartProductRequest>({
            query: (body) => {
                return {
                    url: getQueryUrl(["shop", "cart", "product"]),
                    method: "POST",
                    body: body,
                };
            },
            invalidatesTags: ["CartProducts"],
        }),
        updateCartProductQuantity: builder.mutation<boolean | null, UpdateCartProductQuantityRequest>({
            query: (body) => ({
                url: getQueryUrl(["shop", "cart", "product", "quantity"]),
                method: "PUT",
                body: body,
            }),
            invalidatesTags: ["CartProducts"],
        }),
        clearCart: builder.mutation<boolean, string>({
            query: (id) => {
                return {
                    url: getQueryUrl(["shop", "cart", id, "clear"]),
                    method: "POST",
                };
            },
            invalidatesTags: ["CartProducts"],
        }),
        cartCheckout: builder.mutation<CheckoutInfo | null, string>({
            query: (cartId) => ({
                url: getQueryUrl(["shop", "cart", cartId, "payment-intent"]),
                method: "POST",
            }),
        }),
        buyItNow: builder.mutation<string, BuyItNowRequest>({
            query: (body) => ({
                url: getQueryUrl(["shop", "carts", "buy-it-now"]),
                method: "POST",
                body: body,
            }),
        }),
        deletePaymentIntent: builder.mutation<boolean | null, string>({
            query: (value) => ({
                url: getQueryUrl(["shop", "payment-intent", value]),
                method: "DELETE",
            }),
        }),
        getShopShippingInfo: builder.query<{ costCents: number; brinxBuxToCostRate: number }, void>({
            query: () => {
                return getQueryUrl(["shop", "shipping-info"]);
            },
            providesTags: ["ShopShippingInfo"],
        }),
        updateCartShippingDiscount: builder.mutation<
            void,
            {
                cartId: string;
                value: number;
            }
        >({
            query: (value) => ({
                url: getQueryUrl(["shop", "carts", value.cartId, "shipping-discount", Math.floor(value.value)]),
                method: "PATCH",
            }),
        }),
        getUpsellProducts: builder.query<GetProductsResponseItem[], void>({
            query: () => {
                return getQueryUrl(["shop/products/upsell"]);
            },
            providesTags: ["ShopShippingInfo"],
        }),
    }),
});

export const {
    useGetProductsQuery,
    useLazyGetProductsQuery,
    useGetProductQuery,
    useLazyGetProductQuery,
    useGetCartQuery,
    useLazyGetCartQuery,
    useAddCartProductMutation,
    useUpdateCartProductQuantityMutation,
    useClearCartMutation,
    useCartCheckoutMutation,
    useBuyItNowMutation,
    useDeletePaymentIntentMutation,
    usePrefetch,
    useGetShopShippingInfoQuery,
    useUpdateCartShippingDiscountMutation,
    useGetUpsellProductsQuery,
} = shopApiSlice;
