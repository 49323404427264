import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../app/store/store";
import { Capacitor } from "@capacitor/core";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACK_URL,
    prepareHeaders: (headers) => {
        const token = store.getState().auth.authorizationToken;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        if (Capacitor.isNativePlatform()) {
            headers.set("app-version", process.env.REACT_APP_BACK_VERSION ?? "");
        }

        return headers;
    },
});

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery,
    refetchOnReconnect: true,
    endpoints: () => ({}),
    tagTypes: [
        "StreamChannels",
        "ShowChannels",
        "ShowChannelInfo",
        "VideoInfo",
        "Videos",
        "SubscriptionAccessToken",
        "Categories",

        "Authorize",
        "Admins",

        "ShopPage-Collections",
        "ShopPage-Collections-ProductsOrder",

        "CurrentUser",
        "Auctions",
        "BrinxBux",
        "BrinxBux-Settings",
        "Products",
        "CartProducts",
        "BettingItems",
        "BettingBets",
        "Redeem-Products",
        "RedeemCollections",

        "Notifications",
        "NotificationsAdmin",

        "SweepstakesViralsweep",
        "Sweepstakes",
        "SweepstakeEntries",

        "News",
        "Article",
        "NewsCategories",

        "PpvSubscriptionInfo",
        "ShippingInfo",
        "HasPpvSubscription",

        "UserStreamReservation",
        "UserStreamIsLiveCheck",

        "ChatUserBan",
        "UserBanList",
        "BanWords",

        "AffiliateBrands",
        "UserAffiliateBrands",
        "AffiliateCommissionInfo",

        "PageSettings",
        "PageInfos",
        "ScheduledPageSettings",
        "CurrentPageSettings",

        "PageEventSchedulers",

        "PageSettingsMagicWidgets",
        "PageSettingsSpecificMagicWidgets",
        "PageSettingsShopMagicWidgets",
        "PageSettingsRaiseYourHandMagicWidgets",
        "PageSettingsShopifyAuctionMagicWidgets",
        "PageSettingsBettingMagicWidgets",
        "PageSettingsSweepstakeMagicWidgets",
        "PageSettingsChatMagicWidgets",
        "PageSettingsRedeemMagicWidgets",

        "PageSettingsEventActions",
        "AppSettings",

        "WatchTimeProcessingStatus",
        "SyncPlaylistsStatus",
        "ResetHistory",

        "CompetitionLeaderboard",
        "Competitions",
        "CompetitionParticipants",
        "ShopShippingInfo",
    ],
});
